import React from 'react';

import AppLayout from 'src/layouts/app/AppLayout';
import Heading from 'src/components/content/heading/Heading';

import styles from 'src/scss/pages/404.module.scss';

const NotFoundPage = () => (
  <AppLayout theme="dark">
    <div className={styles.error}>
      <div className={styles.error__message}>
        <div className={styles.error__code}>
          <Heading content="404" priority={1} theme="darkest" wrap />
        </div>
        <div className={styles.error__descriptor}>
          <Heading content="Page Not Found" priority={2} wrap />
        </div>
      </div>
    </div>
  </AppLayout>
);

export default NotFoundPage;
